<template>
  <div>
    <a v-if="hasLink(links,'sages')" href="/sages" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/sages'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/sages')}">Sage Tax Codes</a>
    <a v-if="hasLink(links,'sage-descriptions')" href="/sages_description" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/sages_description'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/sages_description')}">Sage Descriptions </a>
    <a v-if="hasLink(links,'sage-nominal-codes')" href="/sages_nominal_code" type="button" class="rounded-lg px-2 py-1 font-semibold shadow-sm bg-v3-gray-50 mx-1" :class="{'text-v3-gray-600 bg-opacity-10 dark:text-v3-gray-400': !isActive('/sages_nominal_code'), 'text-v3-gray-800 bg-opacity-50 dark:text-v3-gray-200': isActive('/sages_nominal_code')}">Sage Nominal Codes </a>
  </div>
</template>
<script>
export default {
  name: 'SageNavigationButton',
    props:{
      links: {
          type: Object,
          default: null
      }
    },
    methods:{
        hasLink(links,key){
            if(links) {
                return links.filter(link => link.rels.contains(key)).size;
            }
            return false;
        },
        isActive(key){
            return this.$route.path === key
        }
    }
}
</script>
